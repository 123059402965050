<template>
  <div class="scroll-container" @scroll="handleScroll">
    <!-- <div v-if="access_token == ''">
      <router-link to="/register" class="btn btn-primary btn-sm ml-2"><span>Registrasi </span></router-link>
      <router-link to="/login"><button class="btn btn-info btn-sm m-2"><span>Login</span></button></router-link>
    </div>

    <div v-if="email == ''">
      <router-link to="/register" class="btn btn-primary btn-sm ml-2"><span>Registrasi </span></router-link>
      <router-link to="/login"><button class="btn btn-info btn-sm m-2"><span>Login</span></button></router-link>
    </div> -->

    <!-- <GoogleLogin :callback="callback" popup-type="TOKEN" v-show="isLogin == 0">
      <button class="btn btn-info">Login Member</button>
    </GoogleLogin> -->

    <!-- <div class="btn-group ms-sm-3 header-item topbar-user">

    </div> -->

    <div v-if="access_token != '' && email != '' && email != null">
      <div class="btn-group   header-item topbar-user">
        <div style="padding: 10px; ">
          <div style="float: left;" :class="scrollClass">
            <span class="dropdown d-flex align-items-center">
              <small class="text-dark-emphasis dropdown-toggle text-shadow element" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img :src="imageUrl" class=" -toggle flex-shrink-0 me-4 avatar-xs    
           rounded bg-primary-subtle" v-if="imageUrl != 'null' && imageUrl != 'undefined'" />
                <img src="@/assets/images/users/266031.png" class="rounded-circle header-profile-user"
                  v-else-if="imageUrl == 'null' || imageUrl == 'undefined'" />

                <strong class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ email }}</strong>

              </small>
              <ul class="dropdown-menu mt-2">
                <li>
                  <router-link to="/overview">
                    <span class="dropdown-item">
                      Member Area
                    </span>
                  </router-link>
                </li>
                <li @click="clearToken">
                  <router-link to="#">
                    <span class="dropdown-item">
                      <span>Log Out </span>
                    </span>
                  </router-link>

                </li>
              </ul>
            </span>
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <router-link to="/register" class="btn btn-primary btn-sm ml-2"><span>Registrasi </span></router-link>
      <router-link to="/login"><button class="btn btn-info btn-sm m-2"><span>Login</span></button></router-link>
    </div>

  </div>
</template>
<script>
// import { decodeCredential } from 'vue3-google-login'
import axios from 'axios';

export default {
  data() {
    return {
      isLogin: 0,
      responseData: [],
      access_token: localStorage.getItem('access_token'),
      status_login: "Login",
      userProfile: localStorage.getItem('userProfile'),
      name: localStorage.getItem('name'),
      email: localStorage.getItem('email'),
      imageUrl: localStorage.getItem('imageUrl'),
      expires_in: 0,
    }
  },
  watch: {
    access_token() {
      this.profile();
      // cek profile
      this.email = localStorage.getItem('email'),
        this.imageUrl = localStorage.getItem('imageUrl'),
        // this.fetchData();
        this.get_tokens();
      //  / const token_typesense = '';

      //  this.registrasi(token_typesense);
    }
  },
  mounted() {
    this.profile();
    this.email = localStorage.getItem('email');
    this.fetchData();
    if (localStorage.getItem('pageReloaded') == 0) {
      localStorage.setItem('pageReloaded', 1); // Mark the page as reloaded
      this.reloadPage();
    }
  },
  created() {
    localStorage.setItem('userProfile', {
      name: '',
      email: '',
      imageUrl: ''
    });
  },
  methods: {
    handleScroll(event) {
      const scrollThreshold = 50; // Change this value as needed
      const scrollTop = event.target.scrollTop;

      if (scrollTop > scrollThreshold) {
        this.scrollClass = 'color2';
      } else {
        this.scrollClass = 'color1';
      }
    },
    reloadPage() {
      window.location.reload();
    },
    clearToken() {
      localStorage.clear();
      localStorage.setItem('name', '');
      localStorage.setItem('email', '');
      localStorage.setItem('access_token', '');
      this.isLogin = 0;
      window.location.href = "/";
      this.logOut();
    },
    callback(response) {
      localStorage.setItem('access_token', response.access_token);
      this.access_token = response.access_token;
      this.expires_in = response.expires_in;
    },


    async fetchData() {
      // try {
      //   // http://149.28.138.115:8108/collections/data_lokasi/documents/search?q=bandung&query_by=location
      //   const response = await axios.get('http://207.148.122.12:8001/collections/data_lokasi/documents/search', {
      //     params: {
      //       q: 'bandung',
      //       query_by: 'location'
      //     },
      //     headers: {
      //       'x-typesense-api-key': '4zNLoqAglR6J3I4PJ9txWinlGw1Kn5lOqvADpaMPzVL06zjt'
      //     }
      //   });
      //   // Data berhasil diterima dari server
      //   console.log('Data:', response.data);
      // } catch (error) {
      //   // Kesalahan saat melakukan panggilan
      //   console.error('Error:', error);
      // }
    },
    async get_tokens() {
      const self = this;
      await axios({
        method: 'POST',
        url: 'http://207.148.122.12:8001/api/auth/get_tokens',
        params: {
          username: 'typesense',
          password: 'search123!'
        },
        data: {

        },
        headers: {
          'Content-Type': 'text/plain',
          'Cookie': 'session_id=679d1f62e4eef43c96c071b54b0d3feca655b0ed'
        }
      })
        .then(function (response) {
          console.log(response.data)

        }).catch(err => {
          console.log(err)
        });
    },


    async registrasi() {
      const self = this;
      await axios({
        method: 'POST',
        url: 'https://mykkj.kompetensi-teknis.com/api/odn_search.member',
        params: {
          name: localStorage.getItem('name'),
          email: localStorage.getItem('email'),
          avatar: localStorage.getItem('imageUrl'),
          affiliation: '-'
        },
        data: {

        },
        headers: {
          'Content-Type': 'text/plain',
          'Cookie': 'session_id=679d1f62e4eef43c96c071b54b0d3feca655b0ed',
          'Access-Token': '66b6a358cbbc59a93e316c3fc1a6d5594a735437'
        }
      })
        .then(function (response) {
          console.log(response.data)

        }).catch(err => {
          console.log(err)
        });
    },
    async profile() {
      const access_token = localStorage.getItem('access_token')
      const self = this;
      await axios({
        method: 'GET',
        url: 'https://www.googleapis.com/oauth2/v2/userinfo',
        data: {

        },
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
        .then(function (response) {
          localStorage.setItem('name', response.data.name);
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('imageUrl', response.data.picture);
          self.isLogin = 1;
        }).catch(err => {
          console.log(err)
          self.isLogin = 0;
        });
    },

    async logOut() {

      const access_token = localStorage.getItem('access_token')
      const self = this;
      await axios({
        method: 'GET',
        url: 'http://207.148.122.12:8001/api/logout',
        data: {

        },
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
        .then(function (response) {
          localStorage.clear();
          // localStorage.setItem('member_id', '');
          // localStorage.removeItem('member_id');
          // localStorage.setItem('name', response.data.name);
          self.isLogin = 0;
        }).catch(err => {
          console.log(err)
          self.isLogin = 0;
        });
    },



  }
}
</script>
<style>
.text-shadow {
  text-shadow: #ffffff;
  /* Ukur
  an font untuk contoh */
}

.scroll-container {}

.scroll-text {}

/* Initial text color */
.color1 {
  color: black;
}

/* Color when scrolled */
.color2 {
  color: red;
}

.content {
  /* Add some padding to make space for fixed header */
}



.email-text {
  color: #292929;
}

.element {
  cursor: pointer;
}
</style>