<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-landing navbar-light fixed-top" id="navbar">
      <BContainer>
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/images/logo-sm-22.png" class="card-logo card-logo-dark" alt="logo dark" height="67">
          <img src="@/assets/images/logo-sm-11.png" class="card-logo card-logo-light" alt="logo light" height="67">
        </router-link>
        <BButton class="navbar-toggler py-0 fs-20 text-body" type="button" v-b-toggle.navbarSupportedContent>
          <i class="mdi mdi-menu"></i>
        </BButton>
        <BCollapse class="navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
            <li class="nav-item" @click="clickMenu('home')">
              <BLink class="nav-link fs-15" to="/">Home</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('profile')">
              <BLink class="nav-link fs-15" to="/profiles">Profiles
              </BLink>
            </li>
            <li class="nav-item" @click="clickMenu('paper')">
              <BLink class="nav-link fs-15" to="/paper">Paper</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('project')">
              <BLink class="nav-link fs-15" to="/project"> Project</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('patent')">
              <BLink class="nav-link fs-15" to="/patent"> Patent</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('outreach')">
              <BLink class="nav-link fs-15" to="/outreach"> Outreach</BLink>
            </li>

          </ul>

          <div class="">
            <login_member></login_member>
          </div>
        </BCollapse>
      </BContainer>
    </nav>

  </div>
</template>

<script>
import login_member from './MemberLogin.vue'
import mixpanel from "@/config/mixpanel";
export default {
  data() {
    return {
      access_token: localStorage.getItem('access_token'),
    }
  },
  components: {
    login_member
  },
  watch: {
    access_token() {

    }
  },
  methods: {
    clickMenu(page) {
      mixpanel.track('page_menu_click', {
        'page': page,
      });
    },
    reloadPage(page) {
      mixpanel.track('page_menu_click', {
        'page': page,
      });
      window.location.reload(); // Melakukan reload halaman
    }
  }
}

</script>

<style></style>