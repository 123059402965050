
import { Client }  from 'typesense'; 

const status = localStorage.getItem('status');
let embedKey = localStorage.getItem('api-key');
let apiKeys = 'eFv4O5mzDrifH3wKybLbf7Jx9he6xYHu';
if(status =='embed'){
  apiKeys = embedKey
 

}else{
  apiKeys = apiKeys
}

// if (!apiKeys) {
//   console.error('API key is not available in local storage'); 
// }
// 
// OK 
//const typesenseClient = new Client({
//     nodes: [{
//             host: '149.28.138.115',
//             port: '8108',
//             protocol: 'http' // or 'https'
//           }],
//   apiKey: apiKey
// });

const typesenseClient = new Client({
    nodes: [{
            // host: 'arsip.lppm.itb.ac.id',
            host:'jg3hp2inlfqboeyup-1.a1.typesense.net',
            port: '',
            protocol: 'https' // or 'https'
          }],
  // apiKey: '1LR5Dn4snaxc1rs5RwMCfA25LuxGMvvrtb'
  apiKey:apiKeys
});

console.log('ok'+ localStorage.getItem('api-key'))
export default typesenseClient;